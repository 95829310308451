import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    name: '',
    username: ''
  },
  getters: {
    isLogin(state) {
      if (state.token === null)
        return false;
      return true;
    },
  },
  mutations: {
    login(state, payload) {
      state.name = payload.name;
      state.token = payload.token;
      state.username = payload.username;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.token;
      console.log(state);
    },
    logout(state) {
      state.name = null;
      state.token = null;
      state.username = '';
      axios.defaults.headers.common['Authorization'] = null;
    }
  },
  actions: {
  },
  modules: {
  }
})
