import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueJWT from 'vuejs-jwt'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

Vue.use(VueJWT, {})

router.beforeResolve((to, from, next) => {
  if (to.meta.auth) {
    if (store.getters['isLogin'])
      return next();
    return next(false);
  }

  return next();
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
