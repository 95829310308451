<template>
  <v-container class="d-flex" style="height: 100vh">
    <v-row class="text-center my-auto">
      <v-col cols="12">
        <v-card
            max-width="374"
            class="mx-auto my-auto"
        >
          <v-card-title>เข้าสู่ระบบ</v-card-title>
          <v-card-text>
              <v-form>
                <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model="username"
                                label="Username"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model="password"
                                label="Password"
                                type="password"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                        <v-btn
                            color="primary"
                            :loading="isSubmitting"
                            @click="login"
                        >เข้าสู่ระบบ</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isError"
      width="374"
      transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title>Error</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="mt-4">{{errorMessage}}</v-card-text>
            <v-card-actions>
                <v-btn
                    class="white--text ml-auto"
                    color="red"
                    fontColor="white"
                    @click="isError = false"
                >OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        username: '',
        password: '',
        isSubmitting: false,
        isError: false,
        errorMessage: ''
    }),
    methods: {
        async login() {
            const username = this.username;
            const password = this.password;
            try {
                this.isSubmitting = true;
                const ret = await axios.post('/userLogin', {
                    username,
                    password
                });

                const data = ret.data;
                if (data.status == 0) {
                    const payload = this.$jwt.decode(data.token);
                    this.$store.commit('login', {
                        name: payload.name,
                        username: payload.username,
                        token: data.token
                    });
                    this.$router.push({ name: 'Scanner' });
                } else {
                    this.isError = true;
                    this.errorMessage = data.message
                }

            } catch (e) {
                console.log(e);
                this.isError = true;
                this.errorMessage = e;
            } finally {
                this.isSubmitting = false;
            }
        }
    }
}
</script>
